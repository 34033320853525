/* global RequestInit */
/* global RequestInfo */
const originalFetch = window.fetch;

export const fetchWithErrors = async (input: RequestInfo | URL, init?: RequestInit) => {
  try {
    const resp = await originalFetch(input, init);
    if (resp.status >= 499) {
      throw new Error(`HTTP error! status: ${resp.status}`);
    }
    return resp;
  } catch (error) {
    // eslint-disable-next-line no-alert
    alert('A critical error occurred. Please try again later.');

    throw error;
  }
};

window.fetch = fetchWithErrors;

// import { Box } from '@mui/material';
import React from 'react';
// import { Box, keyframes, styled } from '@mui/material';

interface Props {
  im: string;
}

function HeaderBackgroundImage({
  im,
}: Props) {
  return (
    <img
      src={im}
      alt="header-background"
    />
  );
}

export default HeaderBackgroundImage;

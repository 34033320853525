import React from 'react';
import { Box } from '@mui/material';
import { Place } from '@mui/icons-material';
import TypographyHeader from '../layout/TypographyHeader';

function DateLocationComponent() {
  return (
    <Box sx={{ textAlign: 'center', color: '#95C11F' }}>
      <TypographyHeader variant="h2">
        November 27th, 2024
      </TypographyHeader>
      <TypographyHeader variant="h3">
        <Place sx={{ marginRight: '0.5rem', fontSize: 40 }} />
        Spant!, Bussum
      </TypographyHeader>
    </Box>
  );
}

export default DateLocationComponent;

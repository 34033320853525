import React from 'react';
import { Box } from '@mui/material';
// import { useBodyScrollSize } from '../../hooks/useBodyScrollSize';
import HeaderBackgroundImage from './HeaderBackgroundImage';

function HeaderBackground() {
  // const { width } = useBodyScrollSize();

  // const height = 50 + lines;

  return (
    <>
      <Box
        id="header-background"
        sx={{
          position: 'fixed',
          top: 0,
          right: 0,
          zIndex: -10000,
          overflow: 'hidden',
        }}
      >
        <HeaderBackgroundImage im="/lines/background.svg" />
      </Box>
      <Box
        id="header-background"
        sx={{
          position: 'fixed',
          bottom: '-100px',
          zIndex: -10000,
          left: '-30px',
        }}
      >
        <HeaderBackgroundImage im="/lines/cornor.svg" />
      </Box>
    </>
  );
}

export default HeaderBackground;

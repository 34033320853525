import React, { useEffect } from 'react';
import {
  Box, CircularProgress, Container, Grid, styled, Typography,
} from '@mui/material';
import { Client, ProgramPart } from '../../clients/server.generated';
import ActivityComponent from './ActivityComponent';
import { AuthContext } from '../../auth/AuthContextProvider';
import { ActivityWithParticipantAmount } from './ProgramModal';
import PageHeader from '../layout/PageHeader';
// import InfoItem from '../layout/InfoItem';

const Item = styled(Box)(({ theme }) => ({
  backgroundColor: '#EFF1F3',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  height: '100%',
  width: '100%',
  borderRadius: '8px',
  boxShadow: '0px 1px 10px rgba(1,1,1,0.15)',
  border: '0rem solid',
}));

function ProgramComponent() {
  const [activities, setActivities] = React.useState<
    ActivityWithParticipantAmount[] | null
  >(null);
  const [programParts, setProgramParts] = React.useState<ProgramPart[] | null>(
    null,
  );

  const authContext = React.useContext(AuthContext);
  const { user } = authContext;

  const getProgram = () => {
    const client = new Client();

    async function fetchActivities() {
      const res = await client.getAllActivities();
      setActivities(
        res.map((act) => Object.assign(act.activity, {
          nrOfSubscribers: act.nrOfSubscribers,
        })),
      );
    }

    async function fetchProgramParts() {
      const res = await client.getAllProgramParts();
      res.sort((a, b) => (a.beginTime > b.beginTime ? 1 : -1));
      setProgramParts(res);
    }

    fetchProgramParts();
    fetchActivities();
  };

  useEffect(() => {
    getProgram();
  }, []);

  const userSubscriptions = user ? user.subscriptions.map((s) => s.id) : [];

  if (activities != null && programParts != null) {
    const locations = Array.from(
      new Set(activities.map((element) => element.location)),
    );

    const activitiesHtml = programParts.map(
      (programPart) => {
        const activitiesInProgramPart = activities.filter(
          (activity) => activity.programPartId === programPart.id,
        );

        return (
          <>
            <Grid item xs={3}>
              <Item sx={(theme) => ({
                backgroundColor: theme.palette.secondary.main, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column',
              })}
              >
                <Typography variant="h4" sx={{ color: 'white' }}>
                  {programPart.name}
                </Typography>
                <Typography variant="subtitle1" sx={{ color: 'white' }}>
                  {programPart.beginTime.toLocaleTimeString(undefined, { timeZone: 'Europe/Amsterdam', timeStyle: 'short' })}
                  -
                  {programPart.endTime.toLocaleTimeString(undefined, { timeZone: 'Europe/Amsterdam', timeStyle: 'short' })}
                </Typography>
              </Item>
            </Grid>

            {locations.map((location) => {
              const activity = activitiesInProgramPart.filter(
                (ac) => ac.location === location,
              )[0];
              if (!activity) return <Box key={`${programPart.id}-${location}`} />;

            // Condition for border
            const shouldHighlightBorder = activity.subscribe && userSubscriptions.includes(activity.subscribe.id);

            return (
              <Grid item xs={1} key={activity.id}>
                <Item
                  sx={(theme) => ({
                    border: shouldHighlightBorder ? '0.2rem solid' : 'none', // Set border based on condition
                    borderColor: shouldHighlightBorder ? theme.palette.secondary.main : 'transparent', // Set border color
                  })}
                >
                  <ActivityComponent
                    activity={activity}
                    getProgram={getProgram}
                  />
                </Item>
              </Grid>
            );
          })}
          </>
        );
      },
    );

    return (
      <Container maxWidth="md">
        <PageHeader
          title="Program"
          text="The can be found below! Be sure to register for the activities you want to attend.
          Registration starts October 27th, 2024 at noon."
          extraMargin={0}
        />
        <Grid /* Laptop */
          container
          direction="row"
          spacing={2}
          columns={3}
          sx={{ display: { xs: 'none', md: 'flex' } }}
          alignItems="stretch"
        >
          {/* This is an empty box to make the table look nicer */}
          {activitiesHtml}
        </Grid>

        <Grid /* Mobiel */
          container
          direction="row"
          spacing={2}
          columns={1}
          sx={{ display: { xs: 'flex', md: 'none' } }}
        >
          {activitiesHtml}
        </Grid>
      </Container>
    );
  }

  return <CircularProgress />;
}

export default ProgramComponent;

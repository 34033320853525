import React from 'react';
import { styled, Typography } from '@mui/material';
import Container from '@mui/material/Container';

const Paragraph = styled(Typography)({
  paddingTop: '1.5rem',
});

function ThemeComponent() {
  return (
    <Container maxWidth="md" sx={{ textAlign: 'center' }}>
      <Paragraph>
        SNiC is the largest national Computer Science and Artificial Intelligence conference,
        organized for and by students. This edition&apos;s theme of the conference is SustainabilIT,
        where we focus on what the IT sector can do and has done when it comes to sustainable
        technology. We focus on the technology of the past, the technology of today and the
        technology of the future.
      </Paragraph>
    </Container>
  );
}

export default ThemeComponent;

// This has to be the first import in the file.
// so that the global error handler is set up before anything else is imported
import './clients/fetchWithErrors';

import * as Sentry from "@sentry/react";
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import en from 'javascript-time-ago/locale/en';
import TimeAgo from 'javascript-time-ago';
import Router from './Router';
import SNiCTheme from './theme';
import MainMenu from './components/navigation/MainMenu';
import ContextProviders from './contexts/ContextProviders';

console.log('Version:', process.env.REACT_APP_VERSION);

Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    dsn: "https://b763eb8c0abbd42bd71b0aa4d058ebe0@o56951.ingest.us.sentry.io/4507942790234112",
    integrations: [
      Sentry.browserTracingIntegration(),
    ],
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    tracePropagationTargets: [/^https:\/\/sustainabilit\.(snic|svia)\.nl/],
    release: process.env.REACT_APP_VERSION,
});
  

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

TimeAgo.addDefaultLocale(en);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ContextProviders>
          <SNiCTheme>
            <MainMenu>
              <Router />
            </MainMenu>
          </SNiCTheme>
        </ContextProviders>
      </LocalizationProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
